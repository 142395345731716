<template>
	<div>
		<transition-group name="slide-in" :style="{ '--total': value.length }">
			<v-row
				v-for="(data, index) in value"
				v-bind:key="'delivery' + index"
				no-gutters
				:style="{ '--i': index }"
				class="delivery-box mb-3 d-flex add-remove-item"
			>
				<div class="delivery-info d-flex flex-sm-row flex-column">
					<!-- <div class="delivery-map pl-sm-1 pt-sm-1 pa-4 order-sm-1 order-2">
						<div no-gutters class="map-box">
							<img :src="getMapSrc(data)" style="border-radius: 4px" alt />
						</div>
						<div class="d-flex align-center justify-center pb-5" style="margin-top: -60px" alt>
							<div class="map-btn" v-on:click="openMapRoot(data)">
								<img :src="$store.state.icons.icons['DeliveryVan']" />
								Check on map
							</div>
						</div>
					</div> -->

					<div class="delivery-detail d-flex order-sm-2 order-1 my-sm-0 my-4 align-center">
						<div style="position: relative; height: 100%">
							<div v-if="data.status === 'IP'" class="orange-dot"></div>
							<div class="list-icon d-flex align-center justify-center pl-sm-8 pr-sm-8" style="height: 100%">
								<img v-if="data.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt />
								<img v-if="data.category_id === 2" :src="$store.state.icons.icons['Electric']" alt />
								<img v-if="data.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt />
								<img v-if="data.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt />
								<img v-if="data.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt />
								<img v-if="data.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt />
							</div>
						</div>
						<v-divider vertical></v-divider>

						<!-- <div class="list-detail d-flex align-center pl-8 pr-8">
							<div style="width: 100%">
								<p class="heading-mf15">{{ data.list_name }} - {{ data.contractor.fullname }}</p>
								<div class="d-flex">
									<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

								</div>
							</div>
						</div> -->

						<div class="d-flex align-center px-8" style="width: 45%">
							<div>
								<p class="heading-mf15">{{ data.list_name }}- {{ $store.state.auth.user_data.company.name }}</p>
								<p class="heading-rf13">List ref: {{ data.list_id }}</p>
							</div>
						</div>
						<v-divider vertical></v-divider>

						<div style="width: 35%" class="px-4">
							<div class="pt-3 pb-3">
								<p class="heading-mf15">{{ firstAddress(data.list_address) }}</p>
								<p class="heading-rf13">{{ lastAddress(data.list_address) }}</p>
							</div>
						</div>
						<v-divider vertical></v-divider>
						<button class="flex px-sm-8" type="button" v-on:click="openMapRoot(data)">
							<img :src="$store.state.icons.icons['DeliveryVan']" height="auto" width="40px" />
						</button>
					</div>
				</div>

				<div class="status-info d-sm-flex align-center pl-6 pr-6">
					<div class="my-sm-0 my-2 py-2 mobile-border-bottom">
						<p class="heading-mf15">
							{{ $options.filters.capitalize(data.contact_name) }}
						</p>
						<p class="heading-rf13">{{ phoneFormat(data.contact_phone) }}</p>
					</div>
					<div class="ml-auto" v-on:click="bidDelivery(data.bid_id)" style="cursor: pointer">
						<div v-if="data.status === 'IP' && data.pickup === 'Y'" class="d-flex align-center justify-center mr-2">
							<div class="d-flex align-center justify-center mr-4">
								<p class="heading-rf13">Mark as delivered</p>
							</div>
							<div class="d-flex justify-center align-center">
								<div class="delivery-check-box"></div>
							</div>
						</div>
						<div v-else-if="data.status === 'DP'">
							<div
								v-if="checkTime(data.delivery_datetime)"
								class="text-tag-delivery-not text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
							>
								Mark as completed
							</div>
							<div v-else class="text-tag-delivery-not text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">To be delivered</div>
						</div>

						<div
							v-else-if="data.status === 'IP'"
							class="text-tag-delivery-not text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
							style="background: #8d41b1; color: #fff"
						>
							Dispatch
						</div>
						<div
							v-else-if="data.status === 'D'"
							class="text-tag-delivery text-tag-green d-flex align-center justify-center mr-2"
							style="background: #2c9b54; color: #fff"
						>
							Delivery confirmed
						</div>
						<div v-else-if="data.status === 'R'" class="text-tag-reject text-tag-red d-flex align-center justify-center mr-2">
							Situation is being reviewed
							<br />by our customer service
						</div>
					</div>
				</div>
			</v-row>
		</transition-group>
		<App-map-view-root v-model="map_lat_long" ref="openmaproot"></App-map-view-root>
		<app-bid-delivery ref="deliverybid"></app-bid-delivery>
	</div>
</template>

<script>
import moment from 'moment'
const MapViewRoot = () => import('../views/bidcomponent/MapViewRoot')
const BidDelivery = () => import('../views/bidcomponent/BidDelivery')
export default {
	props: {
		value: Array,
	},

	components: {
		AppBidDelivery: BidDelivery,
		AppMapViewRoot: MapViewRoot,
	},

	data() {
		return {
			map_lat_long: {},
		}
	},

	methods: {
		checkTime(date) {
			let delivery_date = moment(date).format('YYYY-MM-DD HH:mm:ss')
			let today = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			if (moment(delivery_date).isBefore(today)) {
				return true
			} else {
				return false
			}
		},

		firstAddress(address) {
			if (address) {
				let first_address = address.split(',')
				return first_address[0]
			}
		},

		lastAddress(address) {
			if (address) {
				let last_address = address.split(',')
				let full_address = last_address[1] != undefined ? last_address[1] : ''
				full_address = last_address[2] != undefined ? full_address + ', ' + last_address[2] : full_address
				full_address = last_address[3] != undefined ? full_address + ', ' + last_address[3] : full_address
				return full_address
			}
		},
		getMapSrc(data) {
			return data.url != null ? data.url['delivery_location'] : 'https://the-build-static-content.s3.us-east-2.amazonaws.com/icons/delivery-map.png'
		},

		phoneFormat(data) {
			if (data) {
				return data.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '($1)-$2-$3')
			}
		},

		openMapRoot(data) {
			this.map_lat_long = {
				category_id: data.category_id,
				list_id: data.list_id,
				list_name: data.list_name,
				list_lat: data.list_lat,
				list_long: data.list_long,
				bid_lat: data.bid_lat,
				bid_long: data.bid_long,
				updated_at: data.list_updated_at,
				status: data.bid_status,
			}
			this.$refs.openmaproot.openMapRoot()
		},

		deliveryDate(data) {
			return moment(String(data)).format('DD.MM.YYYY')
		},

		bidDelivery(bid_id) {
			this.$refs.deliverybid.deliverySubmit(bid_id)
		},
	},
}
</script>

<style lang="scss" scoped>
.delivery-box {
	height: 88px !important;
	background: #ffffff;
	box-shadow: 0 4px 8px 0 rgba(133, 156, 171, 0.15);
	border-radius: 8px;

	.delivery-info {
		width: 60%;
		border-right: 1px solid #e7eaf3;

		.delivery-map {
			max-width: 300;
			width: auto;
			.map-box {
				height: 80px;
				background-color: #ffffff;
				border-radius: 4px;
				z-index: -9;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.map-btn {
				background: #ffffff;
				height: 40px;
				width: 160px;
				border-radius: 4px;
				font-family: Inter-Medium;
				font-size: 13px;
				text-align: center;
				padding: 8px 12px 8px 12px;
				z-index: 5;
				color: #4f5b6d;
				cursor: pointer;
				img {
					height: 22px;
					padding-right: 8px;
				}
			}
		}

		.delivery-detail {
			width: 100%;

			.list-icon {
				img {
					height: 32px;
				}
			}
			@media only screen and (max-width: 1024px) {
				justify-content: flex-end;
			}
		}
	}

	.status-info {
		width: 40%;
	}

	@media only screen and (max-width: 1024px) {
		height: 165px !important;
	}
	@media only screen and (max-width: 830px) {
		height: 200px !important;
	}

	@media only screen and (max-width: 600px) {
		height: 300px !important;
	}

	.orange-dot {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background: #ff8c53;
		margin-top: 10px;
		margin-left: 10px;
		position: absolute;
		box-shadow: 0 6px 8px 0 rgb(255 140 83 / 24%);
	}

	.text-tag-delivery::before {
		content: '';
		top: 1px;
		left: 13px;
		margin-right: 12px;
		width: 7px;
		height: 12px;
		border: solid #fff;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
}
</style>
